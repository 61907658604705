<template>
  <div class="qy">
    <div class="banner">
      <van-image class="image" :src="require('@/assets/order.png')"></van-image>
    </div>
    <van-cell-group class="order" inset>
      <h3 class="couponType">优惠券已使用</h3>
      <van-cell
        title-class="title"
        title-style="width:30%"
        value-class="type"
        title="优惠券："
      >
        <span>{{ coupon }}</span>
        <van-button
          class="tag-coupon"
          style="margin-left: 10px; width: 5em"
          round
          plain
          size="mini"
          :data-clipboard-text="coupon"
          @click="couponCopy()"
          type="info"
          >复制</van-button
        >
      </van-cell>
      <van-divider>订单详情</van-divider>
      <div>
        <van-cell-group v-for="(item, index) in orderList" :key="index">
          <van-cell value-class="type" title="订单编号：">
            <span>{{ item.orderID }}</span>
            <van-button
              class="tag-read"
              :data-clipboard-text="item.orderID"
              @click="copy(item.orderID)"
              style="margin-left: 10px; width: 5em"
              round
              plain
              size="mini"
              type="info"
              >复制</van-button
            >
          </van-cell>
          <van-cell value-class="cellValue" title="兑换商品：">
            <span>{{ item.goodsName }}</span>
          </van-cell>
          <van-cell value-class="cellValue" title="充值账号：">
            <span>{{ item.userID }}</span>
          </van-cell>
          <van-cell value-class="cellValue" title="下单时间：">
            <span>{{ item.createTime }}</span>
          </van-cell>
          <van-cell value-class="type" title="充值状态：">
            <van-tag :type="item.exchangeStatus == '充值成功' ? 'success' : 'primary'">{{
              item.exchangeStatus
            }}</van-tag>
            <van-button
              style="margin-left: 10px; width: 5em"
              round
              plain
              size="mini"
              @click="refreshOrder"
              type="warning"
              >刷新</van-button
            >
          </van-cell>
          <van-divider></van-divider>
        </van-cell-group>
      </div>
      <van-divider>温馨提示</van-divider>
      <div style="height: auto">
        <van-cell>
          <div class="foot_tip">
            <p>
              1、充值到账时间一般在24小时以内，若长时间未到账请联系客服；
            </p>
            <p>
              2、客服电话：<a href="tel:4006-659-619">4006-659-619</a>（09:00-18:00）。
            </p>
          </div>
        </van-cell>
      </div>
    </van-cell-group>
  </div>
</template>
<script>
import { getQueryVariable } from "../utils";
import { api_order } from "@/api/clientOrder";
import Clipboard from "clipboard";

export default {
  components: {},
  data() {
    return {
      coupon: "",
      cid: "",
      orderList: [],
    };
  },
  created() {
    this.coupon = getQueryVariable("coupon");
    this.cid = getQueryVariable("cid");
    if (this.cid) {
      this.getCouponOrder();
    }
  },
  methods: {
    getCouponOrder() {
      api_order(this.cid).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data.data);
          this.orderList = res.data.data;
        }
      });
    },
    refreshOrder() {
      this.getCouponOrder();
    },
    copy() {
      let clipboard = new Clipboard(".tag-read");
      clipboard.on("success", () => {
        this.$toast.fail(`复制成功`);
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$toast.fail("该浏览器不支持自动复制");
        clipboard.destroy();
      });
    },
    couponCopy() {
      let clipboard = new Clipboard(".tag-coupon");
      clipboard.on("success", () => {
        this.$toast.fail(`复制成功`);
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$toast.fail("该浏览器不支持自动复制");
        clipboard.destroy();
      });
    },
  },
};
</script>

<style scoped>
.qy {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #efefef;
  height: 100%;
  position: relative;
  overflow: auto;
}
.banner {
  background: linear-gradient(to bottom, #fb8777, #e77638);
  height: 22em;
  width: 100%;
  border-radius: 0 0 6em 6em;
  position: relative;
  display: flex;
  justify-content: center;
}
.order {
  position: absolute;
  /* bottom: 5em; */
  background: #fff;
  padding: 2em 1em 1em 1em;
  top: 17em;
  height: auto;
  width: 80%;
}
.title {
  letter-spacing: 0.35em;
}
::v-deep van-cell__title title {
  width: 30% !important;
}
.image {
  position: absolute;
  bottom: 60px;
  z-index: 999;
}
.couponType {
  text-align: center;
  color: #fb8777;
}

.cellValue {
  min-width: 72%;
  text-align: left;
}
::v-deep .van-cell {
  padding: 10px 5px;
}
.van-cell__value {
  font-size: 1em;
  color: #333;
}
.type {
  min-width: 72%;
  display: flex;
  justify-content: space-between;
}
.foot_tip {
  font-size: 0.9em;
  color: #969799;
}
.tag-read {
  margin-left: 5px;
}
.tag-coupon {
  margin-left: 5px;
}
</style>
